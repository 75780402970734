import * as React from "react";
import { Layout } from "../layout";
import AboutDeekshaImg from "../../assets/images/deek_smile.png";
import {
  AboutDeeksha,
  AboutText,
  AboutWrapper,
  Overlay,
  Sign,
  TextContainer,
  Title,
} from "../../styles/mobile/mobileAbout.styles";

export const MobileAbout = () => {
  const [loading, setLoading] = React.useState(true);
  const aboutImage = React.useRef();
  React.useEffect(() => {
    const curAboutImg = aboutImage.current as HTMLImageElement;
    if (curAboutImg && curAboutImg.complete) setLoading(false);
  }, [aboutImage]);
  return (
    <Layout bgcolor={"#FFF"} displayLoader={loading}>
      <AboutWrapper>
        <Overlay>
          <Title>Who am I?</Title>
          <AboutDeeksha
            src={AboutDeekshaImg}
            loading={"eager"}
            onLoad={() => {
              setLoading(false);
            }}
            ref={aboutImage}
          />
        </Overlay>
        <TextContainer>
          <AboutText>
            I’m a graduate from Iowa State University (ISU) in Computer
            Engineering and an MBA at University of Toronto, Class of 2023.
          </AboutText>
          <AboutText>
            I love working with various companies providing them with technical
            and business strategies to be a disruptive force in their niche.
          </AboutText>
          <AboutText>
            When I am not working with multiple clients building their solution,
            I like reading, writing and trying new ways to express myself via
            makeup.
          </AboutText>
          <Sign>Deeksha Juneja</Sign>
        </TextContainer>
      </AboutWrapper>
    </Layout>
  );
};
