import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../layout";
import AboutDeekshaImg from "../../assets/images/deeksha_smile.png";
import {
  AboutContainer,
  AboutDeeksha,
  AboutDeekshaContainer,
  AboutDeekshaImageContainer,
  AboutText,
  Dots,
  Overlay,
  Sign,
  TextContainer,
  Title,
} from "../../styles/web/webAbout.styles";
import { SocialContainer } from "../../styles/web/webExperience.styles";
import { Social } from "../socials/socials";

export const WebAbout = () => {
  const [loading, setLoading] = useState(true);
  const [xPos, setXPos] = useState(10);
  const [yPos, setYPos] = useState(10);
  const walk = -15;
  const aboutImage = useRef();
  useEffect(() => {
    const curAboutImg = aboutImage.current as HTMLImageElement;
    if (curAboutImg && curAboutImg.complete) setLoading(false);
  }, [aboutImage]);
  return (
    <Layout bgcolor={"#FFF"} displayLoader={loading}>
      <>
        <AboutContainer>
          <AboutDeekshaImageContainer>
            <Dots />
            <AboutDeeksha
              src={AboutDeekshaImg}
              posX={xPos}
              posY={yPos}
              onMouseMove={(e) => {
                let x = e.nativeEvent.offsetX;
                let y = e.nativeEvent.offsetY;
                const width = e.currentTarget.offsetWidth;
                const height = e.currentTarget.offsetHeight;
                const xWalk = Math.round((x / width) * walk - walk / 2);
                const yWalk = Math.round((y / height) * walk - walk / 2);
                setXPos(xWalk);
                setYPos(yWalk);
              }}
              ref={aboutImage}
              loading={"eager"}
              onLoad={() => {
                setLoading(false);
              }}
            />
          </AboutDeekshaImageContainer>
          <AboutDeekshaContainer>
            <Overlay>
              <Title>Who am I?</Title>
            </Overlay>
            <TextContainer>
              <AboutText>
                I’m a graduate from Iowa State University (ISU) in Computer
                Engineering and an MBA at University of Toronto, Class of 2023.
              </AboutText>
              <AboutText>
                I love working with various companies providing them with
                technical and business strategies to be a disruptive force in
                their niche.
              </AboutText>
              <AboutText>
                When I am not working with multiple clients building their
                solution, I like reading, writing and trying new ways to express
                myself via makeup.
              </AboutText>
              <Sign>Deeksha Juneja</Sign>
            </TextContainer>
          </AboutDeekshaContainer>
        </AboutContainer>
        <SocialContainer>
          <Social alignCenter={true} />
        </SocialContainer>
      </>
    </Layout>
  );
};
